.home {
    position: absolute;
    width: 100%;
    height: 100%;
    // height: 90%;
    // top: 10vh;
    // z-index: 9;
    flex: 1;
    left: 0%;
}

.instructions {
    position: absolute;
    top: 40%;
    left: 40%;
    text-align: center;
}