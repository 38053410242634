.wave-viewer {
    position: relative;
    width: 100vw;
    background-color: black;
    top: 9.75vh;
}
.wave-viewer-mob {
    position: relative;
    width: 100vw;
    background-color: black;
    top: 9.75vh;
}
.wave-canvas {
    //width: 50%;
    top: 15%;
}
 //.wave-canvas {
 //    width: 100vw;
 //    height: 70vh;
 //}

#selection-editor {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    display: none; // Don't display selection editor in multiWaveViewer version
}
#selection-editor:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}