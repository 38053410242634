.menu-pane-container {
  z-index: 22;
  /* opacity: 0.85; */
  padding: 0.5em!important;
  min-width: 625px;
}

.menu-pane {
  margin: 0!important;
}


.timbre-text {
  color: red;
  text-align: center;
}

.timbre-dropdown {
  margin-top: 8%!important;
  min-width: 100px!important;
}

.quantize-margin {
  margin-top: 8%!important;  
}

/* .sound-toggle-container {
  margin: 4% 0;
} */

.harmonics-slider {
  width: 100%;
  /* margin-bottom: 0%!important; */
}

.adsr-slider {
  margin-right: 10px;
}

.adsr-canvas{
  position: relative!important;
  top:0%!important;
  width: 275px;
}

.adsr-container {
  background-color: #282828;
  border: 1px solid #282828;
  border-radius: 0.5em;
  margin-top: 2px;
}

/* .scales-checkbox {
  margin: 10px 0px 10px 0px;
} */

.scales-bottom {
  width: 100%;
  display: flex;
  padding: 0.1em 0.5em
}

#edit-scales-button {
  margin-top: 5px;
  padding: 5.5px 21px;
  font-size: 1rem;
  margin-bottom: 5px;
}
#just-button {
  margin-top: 5px;
  padding: 5.5px 21px;
  font-size: 1rem;
  margin-bottom: 5px;
}

.scale-choice {
  padding: 0.5em!important;
}
.scale-choice.divider {
  box-shadow: 0px 2px 0px -1px rgba(34,36,38,.1) !important;
}



.tuning {
  margin-right: 25%;
}

.no-padding {
  padding: 0px!important;
}

/* Effects Panel Buttons */

#effectSettings,
#effectSettingsWithHeight {
  padding: 4px 11px 1px 11px;
  margin: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 1.25rem;
  height: 26px;
}
#effectSettingsWithHeight {
  height: 40px;
}
#effectSwitch.ui.toggle.button,
#effectSwitch.ui.toggle.button.active {
  padding: 6px 21px 6px 11px;
  width: 116px;
  margin:1px 0px 1px 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-box-shadow: inset -1px 0 0 0 rgba(34,36,38,.15);
  box-shadow: inset -1px 0 0 0 rgba(34,36,38,.15);
}
#toggleButton {
  font-size: 1em;
  /* margin:0px; */
}


/* Don't think these effects-container css below are used anymore due to no dropdown */

.effects-container {
  display: flex;
  align-self: flex-start!important;
  /* width: 100%; */

}

.effects-stretch::before {
  display: none;
}

.effects-stretch {
  min-width: 28%;
  padding-bottom: 0px!important;
  display: flex!important;
  justify-content: flex-start!important;
}

.effects-stretch::after {
  display: none;
}

.effects-dropdown{
  margin-top: 2px;
}

.close-menu-container {
  display: flex!important;
  justify-content: flex-end;
  align-items: center;
}

.close-menu {
  background: none!important;
  padding: 0px!important;
  margin-top: -12px!important;
}

.horiz {
  display: flex;
  margin-top: 2%;
  align-items: center;
}

.vert {
  flex-direction: column;
  padding: 0.1em 0.5em!important;
}

.extra-margin {
  margin: 15px 0px!important;
}

.no-line::before {
  display: none;
}

.ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #7CB342!important;
}

.ui.toggle.checkbox input:checked~label:before {
  background-color: #7CB342!important;
}