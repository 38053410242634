#instructions-bg {
    background-color: rgba(255,255,255,0.2);
    font-family: 'Inconsolata';
    font-size: 20px;
    position: absolute;
    width: auto;
    height: auto;
    margin: 0 auto;
    border-radius: 10px;
    left: 30%;
    transform: translateX(-20%);
    top: 17.5%;
    z-index: 99;
    user-select: none;
  }
  #instructions {
    font-family: 'Inconsolata';
    font-size: 17px;
    color: #ffffff;
    margin: 0 12px 12px 12px;
  }
  #close-instr {
    text-align: right;
  }
  #outside-instr {
    background: rgb(255, 12, 12);
    position: absolute;
    opacity: 0;
    z-index: 98;
    width: 100vw;
    height: 100vh;
  }
  .closing {
    animation: fadeout-instr 2.5s;
    animation-iteration-count: 1;
  }
  
  .flashing {
    animation: blinker 1.5s;
  }
  
  .fullscreenbutton {
    position: absolute;
    top: 10%;
    left: 0%;
    z-index: 21;
    opacity: 0.5;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  
  @keyframes fadeout-instr {
  
    0% { opacity: 1; }
    100% { opacity: 0; }
  }