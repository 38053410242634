body {
  font: 1.25em sans-serif;
}

button,
label {
  font-size: inherit;
  background: transparent;
  border: 2px solid #aaa;
  border-radius: 0.25em;
  padding: 0 0.5em;
  margin: 0.25em;
  outline: none;

  &:disabled {
    color: #aaa;
  }

  &:not(:disabled):hover {
    background: #ddd;
  }

  &:not(:disabled):active {
    background: #888;
    color: #fff;
  }
}

label {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  input[type='checkbox'] {
    margin-right: 1em;
  }
}

// .app {
//   max-width: 512px;
//   margin: auto;
// }

@import url('https://fonts.googleapis.com/css?family=Inconsolata');

body, html {
  margin: 0;
  padding: 0;
  /* user-select: none; */
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
body {
  position: fixed;
  touch-action: none;

}


.app {
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  touch-action: none;
}

// .App-logo {
//   animation: App-logo-spin infinite 20s linear;
//   height: 80px;
// }

// .App-header {
//   background-color: #222;
//   height: 150px;
//   padding: 20px;
//   color: white;
// }

// .App-title {
//   font-size: 1.5em;
// }

// .App-intro {
//   font-size: large;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

// a:visited {
//   text-decoration: none;
//   color: black;
// }

// html {
//   touch-action: none;

// }

// .switch-wrapper {
//     width: 100vw;
//   height: 100vh;
// }
