.modal {
  background-color: white;
  position: absolute;
  z-index: 100;
  width: auto;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  top: 12%;
  border-radius: 10px;
  -webkit-box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
}
.modal .row {
  padding: 4px 4px 4px 4px;
  display: flex;
  flex-direction: row;
}
.modal .column {
  padding: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
}
.modal .header {
  position: relative;
  height: 100%;
  margin: 3%;
}
.modal .title {
  width: 12rem;
  font-size: 1.5rem;
}
.modal .title .subtitle {
  width: 12rem;
  font-size: 1.25rem;
}
.modal .modalBtn {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  /* margin: 3%; */
  bottom: 0%;
}
.modal .step {
  width: 33vw;
}
.modal .img {
  margin: auto;
  max-height: auto;
  width: 20vw;
}
