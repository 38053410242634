$base-color: #2d9fcc;

.bottom-menu-container {
  position: absolute;
  right: 0%;
  bottom: 0%;
}

//   .menu-title {
//     font-size: 24px;
//     align-self: center;
//     text-align: center;
//     transform: translateX(-50%);
//     left: 50%;
//     position: absolute;
//     user-select: none;
//   }

//   .menu-header {
//     font-weight: 700;
//   }

.bottom-menu {
  height: 11vh;
  max-height: 11vh;
  width: fit-content !important;
}

.rangeslider__handle {
  outline: none;
}

.slider {
  width: 100px;
}

.flex {
  display: flex;
  flex-direction: column;
}

.item {
  font-size: 16px !important;
  // border-bottom: 1px solid transparent!important;
}

.icon-button {
  background: none !important;
}

.margin-bottom {
  margin-bottom: 1.5vh;
}

/** Button Grouping **/

.btn-left {
  margin: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-middle {
  margin: 0 !important;
  border-radius: 0 !important;
}

.btn-right {
  margin: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-no-margin {
  margin: 0 !important;
}

/* --- */

.fitted-padding {
  padding: 0.5em !important;
}

#lockwaveBtn.active {
  background-color: $base-color !important;
}

/* .tuning-button:hover {
  outline: none;
  cursor: pointer;
} */

.ui.slider.checkbox input:focus:checked ~ .box:before,
.ui.slider.checkbox input:focus:checked ~ label:before {
  background-color: #abe2fb !important;
}

.ui.slider.checkbox input:checked ~ .box:before,
.ui.slider.checkbox input:checked ~ label:before {
  background-color: #abe2fb !important;
}

.ui.slider.checkbox input:focus ~ .box:before,
.ui.slider.checkbox input:focus ~ label:before {
  background-color: $base-color !important;
}

.ui.slider.checkbox .box:before,
.ui.slider.checkbox label:before {
  background-color: $base-color !important;
}
.ui.toggle.button {
  vertical-align: middle;
}
.ui.toggle.button.active,
.ui.toggle.circular.button.active {
  /* margin-right: 6.325px;
  margin-left: 2.325px; */
  /* Active color */
  background-color: $base-color !important;
}

.hidden {
  display: none;
}

.no-margin {
  margin: 0 !important;
  padding: 0 !important;
}

.app-bar-dropdown-container {
  padding-left: 0px !important;
  z-index: 40;
}

.app-bar-dropdown {
  font-size: 1.25rem;
  // min-width: 13rem!important;
  border: none !important;
  min-height: 1rem !important;
  user-select: none;
  font-variant: small-caps;
  font-weight: 700 !important;
}

.ui.selection.active.dropdown .menu {
  border: none !important;
}

.slider-label {
  margin-right: 0.5rem;
}

.mic.horiz {
  margin-top: 5%;
}
