$base-color: #2d9fcc;

.right-container {
    position: absolute;
    right: 0%;
    top: 10%;
}

.menu-title {
  font-size: 24px;
  align-self: center;
  text-align: center;
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  user-select: none;
}

.menu-header {
  font-weight: 700;
}

.right-menu {
    width: fit-content !important;
}

.menu-pane-container {
  padding: 0.5em !important;
}

.rangeslider__handle {
  outline: none;
}

.slider {
  width: 100px;
  display: inline-block !important;
  margin: 0px !important;
}

.flex {
  display: flex;
  flex-direction: column;
}

.item {
  font-size: 16px !important;
  // border-bottom: 1px solid transparent!important;
}

.icon-button {
  background: none !important;
}

/** Important Buttons **/

.btn-left {
  margin: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.btn-middle {
  margin: 0 !important;
  border-radius: 0 !important;
}

.btn-right {
  margin: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.fitted-padding {
  padding: 0.5em !important;
}

#loop-button.loop-btn.disabled {
  opacity: 0 !important;
}

// #stop-button.disabled {
//   opacity: 0!important;
// }

.importantButtons {
  margin: 0px 0px !important;
  // margin-left: 5px!important;
  // margin-right: 5px!important;
  // margin-top: 0px!important;
  // margin-bottom: 0px!important;
}

.ui.slider.checkbox input:focus:checked ~ .box:before,
.ui.slider.checkbox input:focus:checked ~ label:before {
  background-color: #abe2fb !important;
}

.ui.slider.checkbox input:checked ~ .box:before,
.ui.slider.checkbox input:checked ~ label:before {
  background-color: #abe2fb !important;
}

.ui.slider.checkbox input:focus ~ .box:before,
.ui.slider.checkbox input:focus ~ label:before {
  background-color: $base-color !important;
}

.ui.slider.checkbox .box:before,
.ui.slider.checkbox label:before {
  background-color: $base-color !important;
}
.ui.toggle.button {
  vertical-align: middle;
}
.ui.toggle.button.active,
.ui.toggle.circular.button.active {
  /* margin-right: 6.325px;
  margin-left: 2.325px; */
  /* Active color */
  background-color: $base-color !important;
}

.hidden {
  display: none;
}

#scaleButton {
  width: 92.479px;
  font-size: 1rem;
  /* margin-top: -11px; */
}
.scale-adjust {
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.1) !important;
}

.no-margin {
  margin: 0 !important;
  padding: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

/* .app-bar-overlay{
  position: absolute;
  z-index: 40;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0%;
  top: 0%;
}

.app-bar-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 300px;
  z-index: 50;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
  0px 16px 24px 2px rgba(0, 0, 0, 0.14),
  0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.app-bar-title-container {
  height: 15vh;
  background-color: white;
  display: flex;
  align-items: center;
  position: relative;
}

.app-bar-title {
  padding-left: 1rem;
  text-align: left;
  font-size: 20px;
  color: grey;
}

.app-bar-about {
  position: absolute;
  right: 2%;
  bottom: 2%;
}

.app-bar-hr {
  height: 1px;
  margin: 0;
  border: none; 
  background-color: rgba(0, 0, 0, 0.12);
}

.app-bar-buttons-container {
  display: flex;
  flex-direction:column;
  width: 300px;
  margin-top: 2%;
}

.app-bar-button {
  text-align: left;
  width: 300px;
  margin-bottom: 1rem;
  cursor: pointer;
  padding: 1rem 1rem;
  color: black;
  font-size: 2em;
  outline: none;
  line-height: 1em;
  background-color: white;
  border: 0px;
}

.app-bar-button:hover {
  background-color: #F5F5F5;
}

.app-bar-chevron {
  position: absolute;
  right: 0%;
} */
.app-bar-dropdown-container {
  padding-left: 0px !important;
  z-index: 40;
}

.top-app-bar-dropdown {
  font-size: 1.25rem;
  min-width: 10rem !important;
  border: none !important;
  min-height: 1rem !important;
  user-select: none;
  font-variant: small-caps;
  font-weight: 700 !important;
}

.ui.selection.active.dropdown .menu {
  border: none !important;
}

.slider-label {
  margin-right: 0.5rem;
}

/* For MicControls.js */
#aware-btn {
  padding: 12px;
}
.ui.toggle.circular.button.active:hover {
  background-color: #009fee !important;
}

/* Indicator for Too Long of Recording Time */
button.save-warning {
  padding-right: 0 !important;
}
button.disabled.button.save-warning {
  opacity: 0 !important;
}

.top-menu-button {
  font-size: 12px !important;
}

.left-menu-button {
  height: 2rem;
  flex-direction: column;
  position: relative;
  top: 0%;
  left: 0%;
  z-index: 21;
  //opacity: 0.5;
}
// .selectionButtons {
//   padding: 0px;
//   margin: 0px;
//   font-size: 1.25rem;
// }

.popup-mask{
  top: 8vh;
  left: 0px;
  width: 100vw;
  height: 92vh;
  position: absolute;
  z-index: 1,
}