.slider  > .rangeslider__fill {
    background-color: #2d9fcc !important;
}

.disg > .rangeslider__fill {
    background-color: gray !important;
}

.disg > .rangeslider__handle {
    visibility: hidden !important;
}

// .rangeslider__fill[disabled] {
//     background-color: gray !important;
// }